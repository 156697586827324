import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
    CdkTableModule
  ],
  exports: []
})
export class CdkTableExporterModule { }
